@import "./styles/_variables.scss";

.title-sidenav {
  font-family: Gilroy-Semibold, sans-serif;
  font-size: 20px;
  color: $dark-blue !important;
}

.sidenav > .active.nav-link {
  font-family: Gilroy-bold;
  color: $dark-blue !important;
  margin-left: 14px;
  position: relative;
  &::before {
    content: ">";
    position: absolute;
    left: -6px;
  }
}

.centering-sidenav {
  @media (min-width: 992px) {
    justify-content: center;
  }
}
