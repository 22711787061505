@import "./src/styles/_variables.scss";

.socialLink {
  background-color: $dark-blue;
  border: 1px solid #ffffff4d;
  display: inline-block;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    color: white;
  }

  &:hover {
    background-color: white;
    cursor: pointer;
    & > svg {
      color: $dark-blue;
    }
  }
}
