body {
  margin: 0;
  font-family:
    "Gilroy-Light",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*padding-bottom: 75px;*/
}
.appContent {
  /*background-color: red;*/
  min-height: calc(100vh - 82px);
}
h1 {
  color: #00234d;
  font-size: 34px !important;
  font-family: Hongkong-Regular, sans-serif !important;
}
h2 {
  color: #00234d;
  font-size: 36px !important;
  font-family: Gilroy-Medium, sans-serif !important;
}

h3 {
  color: #00234d;
  font-size: 22px !important;
  font-family: Gilroy-Semibold, sans-serif;
}

p {
  font-size: 16px;
  margin: 0;
}

.p-medium {
  font-size: 16px;
  font-family: Gilroy-medium, sans-serif;
  color: #747d90;
  margin: 0;
}

.p-credits {
  font-family: Gilroy-bold, sans-serif;
  font-size: 40px;
  color: #747d90;
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 34px !important;
  }
  h2 {
    font-size: 26px !important;
  }

  h3 {
    font-size: 18px !important;
  }
  .chapo {
    font-size: 18px;
  }

  p {
    font-size: 16px;
    margin: 0;
  }
}

a.decorated {
  position: relative;
  font-family: Gilroy-Semibold;
  font-size: 10px;
  text-transform: uppercase;
  z-index: 1;
  display: inline-block;
  letter-spacing: 1.5px;
}

a.decorated:hover {
  color: #f54c5d;
}

a.decorated:before {
  content: "> ";
  font-size: 12pt;
  font-family: Gilroy-Bold;
}

a.decorated:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 20px;
  height: 0;
  border-bottom: 2px solid currentColor;
  transition: width 300ms ease-in-out;
}
a.decorated:hover:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  border-bottom: 2px solid #f54c5d;
  color: #f54c5d;
}
a:hover {
  text-decoration: none !important;
  /* color: #f54c5d; */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.indexedStep {
  color: black;
  width: 40px;
  height: 40px;
  padding: 10px;
  /*font-size: 12px;*/
  /*background-color: rgba(211, 211, 211, 0.8);*/
  border-color: #343a40;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  flex-shrink: 0;
  font-family: Gilroy-Semibold;
  margin-right: 10px;
  padding-top: 12px;
  cursor: pointer;
}

.indexedStep.accomplished {
  color: #00234d;
  background-color: #ffffff;
  border-color: #00234d;
}

.indexedStep.current {
  color: #ffffff;
  background-color: #f54c5d;
  border-color: #f54c5d;
}

.indexedStep.not-accomplished {
  color: #00234d4d;
  background-color: white;
  border-color: #00234d4d;
}

.indexedStep.not-accomplished + button {
  color: #00234d4d;
}

.RSPBprogressBar {
  /*height: 10px;*/
  width: 100%;
  line-height: 1;
  border-radius: 10px;
  position: relative;
  /*background-color:*/
  /*        rgba(211, 211, 211, 0.6);*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
  margin-bottom: 50px;
}
.RSPBstep {
  width: 100%;
  height: 50px;
  display: flex;
}
.myStep {
  flex-shrink: 1;
  display: flex;
  text-overflow: clip;
  height: 50px !important;
  align-items: baseline;
}
.myStep button {
  padding: 0 !important;
  height: 100% !important;
  flex-shrink: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
  font-family: Gilroy-Medium, sans-serif;
}

.RSPBstep:after {
  content: " ";
  width: 10%;
  height: 2px;
  background-color: #dbdbdb;
  align-self: center;
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: 5px;
  margin-right: 10px;
  overflow: hidden;
}
.RSPBstep:nth-last-child(2):after {
  content: "";
  display: none;
}
.RSPBprogression {
  display: none;
}
/*.myStep p{*/
/*  height: 50px;*/
/*  flex-grow: 1;*/
/*  display: flex;*/
/*  justify-content: left;*/
/*  align-items: center;*/
/*  margin-left: 10px;*/
/*  margin-right: 20px;*/
/*  width: 0;*/
/*}*/
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
}
.fullHeight {
  height: 100% !important;
  min-height: calc(100vh - 82px);
  justify-content: center !important;
  align-items: flex-start !important;
}
.btn-toolbar-size {
}
.btn {
  /*border-radius: 3rem !important;*/
  font-family: "Gilroy-Medium", sans-serif;
  /*font-size: 16pt !important;*/
  /*height: 60px !important;*/
  /*line-height: 24pt !important;*/
  /*padding-left: 2em !important;*/
  /*padding-right: 2em !important;*/
}
.btn-lg {
  /*height: 70px !important;*/
  /*font-size: 20pt !important;*/
  /*padding-left: 2em !important;*/
  /*padding-right: 2em !important;*/
}
.btn-primary {
  /*background-color: #F54C5D !important;*/
  /*border-color: #F54C5D !important;*/
}
.chapo {
  font-family: "Gilroy-Light", sans-serif;
  color: white;
  font-size: 22px;
  opacity: 0.7;
}

.subtitle {
  font-family: "Gilroy-Semibold", sans-serif;
  color: #546e8c;
  font-size: 12px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}

p {
  font-family: "Gilroy-Light";
  color: #546e8c;
}

.input-group > .input-group-append > .btn {
  border-radius: 0.25rem !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.appnavbar {
  background-color: #00234d !important;
  /* border-bottom: 1px solid #e5e5e5; */
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.hp-nav {
  margin: 0 auto;
}
.maxWidthContainer {
  max-width: 1400px;
}

.sideNav {
  background-color: #f2f2f2 !important;
  max-width: 800px;
  margin: 0 auto;
}
.bottomNavbar {
  background-color: #00234d !important;
  color: white;
}
.bottomNavbar a {
  color: white !important;
}
.withMarginRight {
  margin-right: 15px;
  line-height: 32px !important;
}
.hp h1 {
  color: white;
  font-size: 50pt;
  font-family: Gilroy-Bold, sans-serif;
}
/* .hp h2 {
  font-size: 12pt;
  color: #546e8c;
  text-transform: uppercase;
  font-family: Gilroy-Semibold, sans-serif;
} */
.hp .oldH3 {
  font-size: 50px !important;
  font-family: Gilroy-Medium, sans-serif;
}

.hp h4 {
  font-size: 22px;
  font-family: Gilroy-Semibold, sans-serif;
}
.hp .oldH2 {
  text-transform: uppercase;
  font-family: Gilroy-Semibold, sans-serif;
  color: #546e8c;
  font-size: 12px !important;
  letter-spacing: 2.4px;
  margin-bottom: 1rem;
  font-weight: 500;
  line-height: 1.2;
}
hp .oldH4 {
  margin-bottom: 50px;
  font-size: 22px;
  font-family: Gilroy-Semibold, sans-serif;
}
.hp .oldH5 {
  font-size: 1.25rem;
}

.hp .chapo-light {
  color: #747d90;
  font-size: 22px;
  font-family: Gilroy-Light, sans-serif;
}

.hp .cardItemText {
  color: #253451;
  font-size: 18px;
  font-family: Gilroy-Medium, sans-serif;
  line-height: 32px;
}

.hp .white {
  color: #ffffff;
}

.hp .bold {
  font-family: Gilroy-Bold, sans-serif;
}

.raised {
  box-shadow:
    0 19px 38px rgba(0, 0, 0, 0.3),
    0 15px 12px rgba(0, 0, 0, 0.22);
}
.navy {
  background-color: #00234d;
  color: white;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}
@font-face {
  font-family: "Gilroy-Black";
  src:
    url("fonts/Gilroy/Gilroy-Black.woff2") format("woff2"),
    url("fonts/Gilroy/Gilroy-Black.woff") format("woff"),
    url("fonts/Gilroy/Gilroy-Black.ttf") format("truetype"),
    url("fonts/Gilroy/Gilroy-Black.svg") format("svg"),
    url("fonts/Gilroy/Gilroy-Black.otf") format("opentype"),
    url("fonts/Gilroy/Gilroy-Black.eot?#iefix") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-BlackItalic";
  src:
    url("fonts/Gilroy/Gilroy-BlackItalic.woff2") format("woff2"),
    url("fonts/Gilroy/Gilroy-BlackItalic.woff") format("woff"),
    url("fonts/Gilroy/Gilroy-BlackItalic.ttf") format("truetype"),
    url("fonts/Gilroy/Gilroy-BlackItalic.svg") format("svg"),
    url("fonts/Gilroy/Gilroy-BlackItalic.otf") format("opentype"),
    url("fonts/Gilroy/Gilroy-BlackItalic.eot?#iefix") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-BoldItalic";
  src:
    url("fonts/Gilroy/Gilroy-BoldItalic.woff2") format("woff2"),
    url("fonts/Gilroy/Gilroy-BoldItalic.woff") format("woff"),
    url("fonts/Gilroy/Gilroy-BoldItalic.ttf") format("truetype"),
    url("fonts/Gilroy/Gilroy-BoldItalic.svg") format("svg"),
    url("fonts/Gilroy/Gilroy-BoldItalic.otf") format("opentype"),
    url("fonts/Gilroy/Gilroy-BoldItalic.eot?#iefix") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-Extrabold";
  src:
    url("fonts/Gilroy/Gilroy-Extrabold.woff2") format("woff2"),
    url("fonts/Gilroy/Gilroy-Extrabold.woff") format("woff"),
    url("fonts/Gilroy/Gilroy-Extrabold.ttf") format("truetype"),
    url("fonts/Gilroy/Gilroy-Extrabold.svg") format("svg"),
    url("fonts/Gilroy/Gilroy-Extrabold.otf") format("opentype"),
    url("fonts/Gilroy/Gilroy-Extrabold.eot?#iefix") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-ExtraboldItalic";
  src:
    url("fonts/Gilroy/Gilroy-ExtraboldItalic.woff2") format("woff2"),
    url("fonts/Gilroy/Gilroy-ExtraboldItalic.woff") format("woff"),
    url("fonts/Gilroy/Gilroy-ExtraboldItalic.ttf") format("truetype"),
    url("fonts/Gilroy/Gilroy-ExtraboldItalic.svg") format("svg"),
    url("fonts/Gilroy/Gilroy-ExtraboldItalic.otf") format("opentype"),
    url("fonts/Gilroy/Gilroy-ExtraboldItalic.eot?#iefix") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-Heavy";
  src:
    url("fonts/Gilroy/Gilroy-Heavy.woff2") format("woff2"),
    url("fonts/Gilroy/Gilroy-Heavy.woff") format("woff"),
    url("fonts/Gilroy/Gilroy-Heavy.ttf") format("truetype"),
    url("fonts/Gilroy/Gilroy-Heavy.svg") format("svg"),
    url("fonts/Gilroy/Gilroy-Heavy.otf") format("opentype"),
    url("fonts/Gilroy/Gilroy-Heavy.eot?#iefix") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-HeavyItalic";
  src:
    url("fonts/Gilroy/Gilroy-HeavyItalic.woff2") format("woff2"),
    url("fonts/Gilroy/Gilroy-HeavyItalic.woff") format("woff"),
    url("fonts/Gilroy/Gilroy-HeavyItalic.ttf") format("truetype"),
    url("fonts/Gilroy/Gilroy-HeavyItalic.svg") format("svg"),
    url("fonts/Gilroy/Gilroy-HeavyItalic.otf") format("opentype"),
    url("fonts/Gilroy/Gilroy-HeavyItalic.eot?#iefix") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-LightItalic";
  src:
    url("fonts/Gilroy/Gilroy-LightItalic.woff2") format("woff2"),
    url("fonts/Gilroy/Gilroy-LightItalic.woff") format("woff"),
    url("fonts/Gilroy/Gilroy-LightItalic.ttf") format("truetype"),
    url("fonts/Gilroy/Gilroy-LightItalic.svg") format("svg"),
    url("fonts/Gilroy/Gilroy-LightItalic.otf") format("opentype"),
    url("fonts/Gilroy/Gilroy-LightItalic.eot?#iefix") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-ExtraboldItalic";
  src:
    url("fonts/Gilroy/Gilroy-ExtraboldItalic.woff2") format("woff2"),
    url("fonts/Gilroy/Gilroy-ExtraboldItalic.woff") format("woff"),
    url("fonts/Gilroy/Gilroy-ExtraboldItalic.ttf") format("truetype"),
    url("fonts/Gilroy/Gilroy-ExtraboldItalic.svg") format("svg"),
    url("fonts/Gilroy/Gilroy-ExtraboldItalic.otf") format("opentype"),
    url("fonts/Gilroy/Gilroy-ExtraboldItalic.eot?#iefix") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-MediumItalic";
  src:
    url("fonts/Gilroy/Gilroy-MediumItalic.woff2") format("woff2"),
    url("fonts/Gilroy/Gilroy-MediumItalic.woff") format("woff"),
    url("fonts/Gilroy/Gilroy-MediumItalic.ttf") format("truetype"),
    url("fonts/Gilroy/Gilroy-MediumItalic.svg") format("svg"),
    url("fonts/Gilroy/Gilroy-MediumItalic.otf") format("opentype"),
    url("fonts/Gilroy/Gilroy-MediumItalic.eot?#iefix") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-RegularItalic";
  src:
    url("fonts/Gilroy/Gilroy-RegularItalic.woff2") format("woff2"),
    url("fonts/Gilroy/Gilroy-RegularItalic.woff") format("woff"),
    url("fonts/Gilroy/Gilroy-RegularItalic.ttf") format("truetype"),
    url("fonts/Gilroy/Gilroy-RegularItalic.svg") format("svg"),
    url("fonts/Gilroy/Gilroy-RegularItalic.otf") format("opentype"),
    url("fonts/Gilroy/Gilroy-RegularItalic.eot?#iefix") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-SemiboldItalic";
  src:
    url("fonts/Gilroy/Gilroy-SemiboldItalic.woff2") format("woff2"),
    url("fonts/Gilroy/Gilroy-SemiboldItalic.woff") format("woff"),
    url("fonts/Gilroy/Gilroy-SemiboldItalic.ttf") format("truetype"),
    url("fonts/Gilroy/Gilroy-SemiboldItalic.svg") format("svg"),
    url("fonts/Gilroy/Gilroy-SemiboldItalic.otf") format("opentype"),
    url("fonts/Gilroy/Gilroy-SemiboldItalic.eot?#iefix") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-Thin";
  src:
    url("fonts/Gilroy/Gilroy-Thin.woff2") format("woff2"),
    url("fonts/Gilroy/Gilroy-Thin.woff") format("woff"),
    url("fonts/Gilroy/Gilroy-Thin.ttf") format("truetype"),
    url("fonts/Gilroy/Gilroy-Thin.svg") format("svg"),
    url("fonts/Gilroy/Gilroy-Thin.otf") format("opentype"),
    url("fonts/Gilroy/Gilroy-Thin.eot?#iefix") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-ThinItalic";
  src:
    url("fonts/Gilroy/Gilroy-ThinItalic.woff2") format("woff2"),
    url("fonts/Gilroy/Gilroy-ThinItalic.woff") format("woff"),
    url("fonts/Gilroy/Gilroy-ThinItalic.ttf") format("truetype"),
    url("fonts/Gilroy/Gilroy-ThinItalic.svg") format("svg"),
    url("fonts/Gilroy/Gilroy-ThinItalic.otf") format("opentype"),
    url("fonts/Gilroy/Gilroy-ThinItalic.eot?#iefix") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-UltraLight";
  src:
    url("fonts/Gilroy/Gilroy-UltraLight.woff2") format("woff2"),
    url("fonts/Gilroy/Gilroy-UltraLight.woff") format("woff"),
    url("fonts/Gilroy/Gilroy-UltraLight.ttf") format("truetype"),
    url("fonts/Gilroy/Gilroy-UltraLight.svg") format("svg"),
    url("fonts/Gilroy/Gilroy-UltraLight.otf") format("opentype"),
    url("fonts/Gilroy/Gilroy-UltraLight.eot?#iefix") format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-UltraLightItalic";
  src:
    url("fonts/Gilroy/Gilroy-UltraLightItalic.woff2") format("woff2"),
    url("fonts/Gilroy/Gilroy-UltraLightItalic.woff") format("woff"),
    url("fonts/Gilroy/Gilroy-UltraLightItalic.ttf") format("truetype"),
    url("fonts/Gilroy/Gilroy-UltraLightItalic.svg") format("svg"),
    url("fonts/Gilroy/Gilroy-UltraLightItalic.otf") format("opentype"),
    url("fonts/Gilroy/Gilroy-UltraLightItalic.eot?#iefix") format("embedded-opentype");
  font-display: swap;
}

.decoratedRed {
  color: #f54c5d !important;
}
.round {
  height: 40px;
  width: 40px;
  border-radius: 50% !important;
  box-shadow:
    0 0 1px 0px white inset,
    0 0 1px 0px white;
}
.accountNavBar {
  line-height: 1;
  padding-bottom: 0.2em !important;
  padding-top: 0.2em !important;
  height: 40px !important;
}
.btn-link {
  padding: 0 !important;
}
.faEye {
  padding: 0.1em 0.5em !important;
  display: flex;
  justify-content: center;
}
.alert-light {
  /*font-size: 0.9rem;*/
  font-family: Gilroy-Medium, sans-serif;
  background-color: #f5f6f8 !important;
}
.react-pdf__Document {
  height: 100vh;
  overflow: auto;
}
.md img {
  max-width: 100%;
}
.thumbnail {
  height: 99px !important;
  width: 70px !important;
  overflow: hidden !important;
  border: 1px solid grey;
  flex-shrink: 0;
}
.addressBody p {
  margin-bottom: 0.3rem;
}
.createLetterImageButton {
  position: relative;
  /*height: 40px;*/
  /*width: 40px;*/
  /*border-color: transparent !important;*/
  border-radius: 50% !important;
  box-shadow:
    0 0 1px 0px white inset,
    0 0 1px 0px white;
}
.createLetterImageButton:hover {
  background: #f54c5d !important;
}
.fEHtLo {
  background: transparent !important;
}
.breadcrumb {
  background-color: transparent !important;
}

.layout-flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.card-body-tarif {
  font-size: 16px;
  padding: 40px;
}

.semi-bold {
  font-family: Gilroy-Semibold, serif;
}
.iconButton {
  width: 35px;
  height: 35px;
  padding: 0 !important;
}
.modal-content {
  border-radius: 0em !important;
}
.modal-header {
  background-color: #00234d;
  color: white !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.modal-header .close {
  color: white !important;
}
.modal-content {
  border-radius: 0.05em !important;
}
.card {
  border-radius: 0.05em !important;
}
.alert {
  border-radius: 0 !important;
}
.jumbotron {
  border-radius: 0 !important;
}
.octicon-link {
  visibility: hidden !important;
}
