@mixin semibold {
  font-family: Gilroy-Semibold, sans-serif;
}

@mixin medium {
  font-family: Gilroy-Medium, sans-serif;
}

@mixin bold {
  font-family: Gilroy-Bold, sans-serif;
}
