@import "./src/styles/mixins/_fonts.scss";
@import "./src/styles/_variables.scss";

.icon {
  position: absolute;
  right: 30px;
  top: 22px;
}

.customToggleWrapper {
  @include semibold;
  font-size: 20px;
  padding: 20px;
}

.dropDownMenu {
  background-color: $light-grey;
  margin-top: 0;
  border: none;
  font-size: 20px;
  padding: 20px;
  @include medium;

  a {
    padding: 0.25rem 0;
  }
}
