@import "./src/styles/mixins/_fonts.scss";

.footerItem {
  opacity: 0.5;
}

.contact {
  @include semibold;
  font-size: 22px;
  margin-bottom: 1rem;
}
